<template>
  <div>
    <Loader :class="{ show: globalStore.isLoading }" />
    <main class="main-content mt-0">
      <slot />
    </main>
  </div>
</template>

<script setup>
import Loader from "~/components/Layout/Loader.vue";

const { globalStore } = useNuxtApp();
const route = useRoute();
const { runtimeConfig } = useNuxtApp();

useHead({
  titleTemplate: (titleChunk) => {
    return `${titleChunk} - ${route.meta.title} ${runtimeConfig.public.titleSeparator} ${runtimeConfig.public.siteName}`;
  },
});

useSeoMeta({
  ogImage: "/images/tixu.jpg",
  description: runtimeConfig.public.siteDescription,
});
</script>
